import { SortingState } from "@tanstack/react-table";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  RawAxiosRequestHeaders,
} from "axios";
import Fuse from "fuse.js";
import qs from "query-string";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { downloadBlob } from "utils/common";
import { AuthTokenBag } from "models/common";
import {
  destroyRefreshToken,
  destroySessionToken,
  refreshToken,
} from "services/auth";

export type Query = Record<string, any>;

let SESSION_TOKEN: string | null = null;

export const setSessionToken = (token: string) => {
  SESSION_TOKEN = token;
};

export type PagedQuery = {
  page?: number;
  size?: number;
  search?: string;
  sorting?: SortingState;
  sort?: string[];
} & Query;

export type DataFilter<T = any> = (data: T[], query: PagedQuery) => T[];

export type PagedResponse<T = {}> = {
  data: T[];
  meta: {
    total: number;
    pageNumber: number;
    pageSize: number;
    totalPages: number;
  };
  query: PagedQuery;
};

type RequestOptions = {
  query?: Query;
} & AxiosRequestConfig;

export type ApiErrorPayload = {
  message?: string;
  error?: string;
  status: string;
  timestamp: string;
  type: string;
};

export class ApiError extends Error {
  constructor(public payload: ApiErrorPayload, public status = 500) {
    super(payload.message ?? payload.error);
  }
}

export class Downloadable {
  constructor(public blob: Blob, public fileName: string) {}

  download(fileName?: string) {
    downloadBlob(this.blob, fileName ?? this.fileName);
  }
}

axios.interceptors.request.use(
  (config) => {
    if (SESSION_TOKEN) {
      config.headers.set("Authorization", `Bearer ${SESSION_TOKEN}`);
    }
    return config;
  },
  (config) => {
    console.log(config);
  },
);

let isRefreshing = false;
let subscribers: ((authTokenBag?: AuthTokenBag) => void)[] = [];

axios.interceptors.response.use(
  (res) => res,
  (err: AxiosError<{ type: string }>) => {
    const { config: originalRequest } = err;
    // if 401, attempt to refresh
    if (
      err.response?.status === 401 &&
      err.response?.headers["x-unauthorized-reason"] === "token-expired" &&
      originalRequest
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshToken()
          .then((authTokenBag) => {
            isRefreshing = false;
            subscribers.map((cb) => cb(authTokenBag));
            subscribers = [];
          })
          .catch(() => {
            subscribers.map((cb) => cb());
          });
      }
      return new Promise((resolve, reject) => {
        subscribers.push((authTokenBag?: AuthTokenBag) => {
          if (authTokenBag) {
            originalRequest.headers.set(
              "Authorization",
              `Bearer ${authTokenBag.token}`,
            );
            resolve(axios(originalRequest));
          } else {
            reject();
          }
        });
      });
    }

    if (
      err.response?.status === 400 &&
      err.response?.data?.type === "EventIsDisabledException"
    ) {
      toast.error("This event is disabled. Contact admin for more information");
      toast.onChange((payload) => {
        if (payload.status === "removed") {
          window.location.replace("/");
        }
      });

      (async () => {
        await destroySessionToken();
        await destroyRefreshToken();
        SESSION_TOKEN = null;
      })();
    }
    return Promise.reject(err);
  },
);

/**
 * Wrap over the window.fetch object for hijacking the headers
 */
const _fetch = async <T = any>(
  api: string,
  opt?: RequestOptions,
): Promise<T> => {
  // 1. Query
  let url =
    (process.env.REACT_APP_API_ENDPOINT || "") + "/" + api.replace(/^\//, "");
  if (opt && opt.query) {
    url += "?" + qs.stringify(opt.query);
  }

  try {
    const response = await axios.request<T>({
      url,
      ...(opt || {}),
    });

    // Downloadable hack
    if (response.data instanceof Blob) {
      const dispositionHeader = response.headers["content-disposition"];
      let fileName = "download";
      if (dispositionHeader) {
        fileName = dispositionHeader.replace("attachment; filename=", "");
      }
      return new Downloadable(response.data, fileName) as T;
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new ApiError(
        error.response.data as ApiErrorPayload,
        error.response.status,
      );
    }
    throw error;
  }

  // const response = await window.fetch(url, {
  //   method,
  //   headers: {
  //     ...{ Accept: "application/json" },
  //     ...(!opt || opt.data instanceof FormData
  //       ? {}
  //       : { "Content-Type": "application/json" }),
  //     ...(SESSION_TOKEN ? { Authorization: "Bearer " + SESSION_TOKEN } : {}),
  //     ...(opt ? opt.headers || {} : {}),
  //   },
  //   body:
  //     opt && opt.data
  //       ? opt.data instanceof FormData || typeof opt.data === "string"
  //         ? opt.data
  //         : JSON.stringify(opt.data)
  //       : undefined,
  // });
  //
  // if (response.ok) {
  //   if (response.status === 204) return {} as T;
  //   try {
  //     // force download binary
  //     if (
  //       response.headers.get("content-type") === "application/force-download"
  //     ) {
  //       const dispositionHeader = response.headers.get("content-disposition");
  //       let fileName = "download";
  //       if (dispositionHeader) {
  //         fileName = dispositionHeader.replace("attachment; filename=", "");
  //       }
  //       return new Downloadable(await response.blob(), fileName) as T;
  //     } else {
  //       return await response.json();
  //     }
  //   } catch (err) {
  //     return {} as T;
  //   }
  // } else {
  //   throw new ApiError(await response.json());
  // }
};

export const mock = async <T = any>(
  data: T | (() => T | undefined),
): Promise<T> => {
  if (data instanceof Function) {
    const ret = data();
    if (ret !== undefined) return ret;
    throw new Error("Not found");
  }
  return data;
};

export const head = async <T = any>(url: string, opt: RequestOptions = {}) =>
  _fetch<T>(url, { ...opt, method: "head" });

export const get = async <T = any>(url: string, opt: RequestOptions = {}) =>
  _fetch<T>(url, { ...opt, method: "get" });

export const post = async <T = any>(url: string, opt: RequestOptions = {}) =>
  _fetch<T>(url, { ...opt, method: "post" });

export const put = async <T = any>(url: string, opt: RequestOptions = {}) =>
  _fetch<T>(url, { ...opt, method: "put" });

export const patch = async <T = any>(url: string, opt: RequestOptions = {}) =>
  _fetch<T>(url, { ...opt, method: "patch" });

export const del = async <T = any>(url: string, opt: RequestOptions = {}) =>
  _fetch<T>(url, { ...opt, method: "delete" });

export const downloadable = async <T = Downloadable>(
  url: string,
  opt?: RequestOptions,
) => _fetch<T>(url, { ...(opt || {}), responseType: "blob" });

type PagedOptions<T extends object = any> = {
  searchableFields?: string[];
  dataFilter?: DataFilter<T>;
  query?: Query;
};

export class Paged<T extends object = any> {
  protected query: PagedQuery = {
    page: 1,
    size: 20,
  };
  protected headers: RawAxiosRequestHeaders = {};
  protected sorting: SortingState = [];
  protected dataFilter: DataFilter<T>;
  protected mockData?: T[] | (() => T[]);
  protected searchableFields$: string[] = [];
  protected transformer$: (data: T[]) => T[] = (data) => data;
  protected onGet$: (res: PagedResponse<T>) => void = () => {};
  protected onAll$: (data: T[]) => void = () => {};

  constructor(public url: string, opt?: PagedOptions<T>) {
    const { searchableFields, dataFilter, query } = opt ?? {};
    this.query = { ...this.query, ...(query || {}) };
    this.searchableFields$ = searchableFields ?? [];
    this.dataFilter =
      dataFilter ||
      ((data: T[], query: PagedQuery) => {
        const { search, sorting } = query;
        let result = data;
        if (search && data.length > 0) {
          // Then we use fuse to search
          // https://fusejs.io/api/options.html
          const fuse = new Fuse<T>(data, {
            includeScore: true,
            threshold: 0.2,
            keys: this.searchableFields$ ?? Object.keys(data[0]),
            fieldNormWeight: 1,
          });
          result = fuse.search(search).map((r) => r.item);
        }

        if (sorting) {
          result = result.slice(0);
          sorting.forEach((sort) => {
            result.sort((a, b) => {
              const f = sort.id as keyof T;
              if (a[f] > b[f]) return sort.desc ? -1 : 1;
              if (a[f] < b[f]) return sort.desc ? 1 : -1;
              return 0;
            });
          });
        }
        return result;
      });
  }

  public mock(mock: T[] | (() => T[])): this {
    this.mockData = mock;
    return this;
  }

  public onGet(fn: (res: PagedResponse<T>) => void): this {
    this.onGet$ = fn;
    return this;
  }

  public onAll(fn: (data: T[]) => void): this {
    this.onAll$ = fn;
    return this;
  }

  sort(sorting: SortingState): this {
    this.query.sorting = this.sorting = sorting;
    this.query.sort = sorting.map(
      (s) => s.id + "," + (s.desc ? "desc" : "asc"),
    );
    return this;
  }

  page(page: number): this {
    this.query.page = page;
    return this;
  }

  size(size: number): this {
    this.query.size = size;
    return this;
  }

  keyword(keyword: string): this {
    this.query.keyword = keyword;
    return this;
  }
  addQueryParameter(key: string, value: string): this {
    this.query[key] = value;
    return this;
  }

  search(search: string): this {
    this.query.search = search;
    return this;
  }

  searchableFields(searchableFields: string[]): this {
    this.searchableFields$ = searchableFields;
    return this;
  }

  transformer(transformer: (data: T[]) => T[]): this {
    this.transformer$ = transformer;
    return this;
  }

  async get(): Promise<PagedResponse<T>> {
    // run through the query if data is an array
    if (this.mockData !== undefined) {
      let filteredData;
      if (Array.isArray(this.mockData)) {
        filteredData = this.dataFilter(this.mockData, this.query);
      } else {
        filteredData = this.dataFilter(this.mockData(), this.query);
      }

      const { page = 1, size = 10 } = this.query;
      const paginatedData = filteredData.slice((page - 1) * size, size * page);

      const res = {
        data: this.transformer$(paginatedData),
        meta: {
          total: filteredData.length,
          pageSize: size,
          pageNumber: page,
          totalPages: Math.floor((filteredData.length - 1) / size) + 1,
        },
        query: this.query,
      } as PagedResponse<T>;
      this.onGet$(res);
      return res;
    } else {
      // mplify server uses page starts from 0, so we need to change that
      // value on fetch and on response
      const { page, search, ...others } = this.query;
      return get<PagedResponse<T>>(this.url, {
        query: { ...others, page: (page ?? 1) - 1, keyword: search },
        headers: this.headers,
      }).then((res) => {
        // need to convert meta into +1
        const newRes = {
          data: this.transformer$(res.data),
          meta: { ...res.meta, pageNumber: res.meta.pageNumber + 1 },
          query: this.query,
        } as PagedResponse<T>;
        this.onGet$(newRes);
        return newRes;
      });
    }
  }

  async all(): Promise<T[]> {
    if (this.mockData !== undefined) {
      let filteredData;
      if (Array.isArray(this.mockData)) {
        filteredData = this.dataFilter(this.mockData, this.query);
      } else {
        filteredData = this.dataFilter(this.mockData(), this.query);
      }
      const data = filteredData.slice(0);
      this.onAll$(data);
      return Promise.resolve(data);
    } else {
      delete this.query.page;
      delete this.query.size;
      return get<PagedResponse<T>>(this.url, {
        headers: this.headers,
        query: {
          ...this.query,
          ...(this.query.search ? { keyword: this.query.search } : {}),
        },
      }).then(({ data }) => {
        const newData = this.transformer$(data);
        this.onAll$(newData);
        return newData;
      });
    }
  }
}

export const paged = <T extends object = {}>(url: string, opt?: PagedOptions) =>
  new Paged<T>(url, opt);
export const usePaged = <T extends object = {}>(
  url: string,
  opt?: PagedOptions,
) => useMemo(() => new Paged<T>(url, opt), [url, opt]);

/**
 * Obtain the firebaseConfig via project > Settings > Add Web Apps and copy the chunk here
 */
const firebaseConfig = {
  apiKey: "AIzaSyDHiHYZOlz9YkRNxAvpE8fPVS2QPBATwTw",
  authDomain: "mplify-b215b.firebaseapp.com",
  databaseURL:
    "https://mplify-b215b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mplify-b215b",
  storageBucket: "mplify-b215b.appspot.com",
  messagingSenderId: "715838460702",
  appId: "1:715838460702:web:3328541dd9b20839e9ef0f",
  measurementId: "G-LGQWEZJTRV",
};

export default firebaseConfig;

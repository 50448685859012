import { post } from "./api";

const MPLIFY_DEVICE_TOKEN = "mplify-device-token";

export const registerDevice = (token: string) => {
  window.localStorage.setItem(MPLIFY_DEVICE_TOKEN, token);
  return post<any>(`devices/register/${token}`);
};

export const deRegisterDevice = (token?: string) => {
  if (!token) {
    token = window.localStorage.getItem(MPLIFY_DEVICE_TOKEN) ?? "";
  }
  if (token) {
    return post<any>(`devices/deregister/${token}`);
  }
  return Promise.resolve();
};

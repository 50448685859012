import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { sortByField } from "utils/common";
import { Event, Language, Organization, User } from "models/common";

export type AppState = {
  user: User | null;
  organization: Organization;
  event: Event;
  notificationStatus: NotificationPermission;
};

const initialState: AppState = {
  user: null,
  organization: {} as Organization,
  event: {} as Event,
  notificationStatus:
    "Notification" in window ? Notification.permission : "default",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state: AppState, action: PayloadAction<User | null>) => {
      state.user = action.payload;
      if (action.payload?.event) {
        state.event = action.payload.event;
      }
      return state;
    },
    setOrganization: (state: AppState, action: PayloadAction<Organization>) => {
      state.organization = action.payload;
      return state;
    },
    setEvent: (state: AppState, action: PayloadAction<Event>) => {
      state.event = action.payload;
      return state;
    },
    setDefaultLanguage: (state: AppState, action: PayloadAction<string>) => {
      const defaultLangStr = (state.event.defaultLanguageName = action.payload);
      state.event.languages.forEach((lang) => {
        lang.default = lang.id === defaultLangStr;
      });
      return state;
    },
    setEventLanguages: (state: AppState, action: PayloadAction<Language[]>) => {
      state.event.languages = action.payload.sort(sortByField("label"));
      return state;
    },
    setNotificationStatus: (
      state: AppState,
      action: PayloadAction<NotificationPermission>,
    ) => {
      state.notificationStatus = action.payload;
      return state;
    },

    resetAppState: (state: AppState) => {
      state.user = null;
      state.event = {} as Event;
      state.organization = {} as Organization;
    },
  },
});

export const {
  setUser,
  setOrganization,
  setEvent,
  setDefaultLanguage,
  setEventLanguages,
  setNotificationStatus,
  resetAppState,
} = appSlice.actions;
export default appSlice.reducer;

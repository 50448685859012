import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { setSessionToken } from "services/api";
import { getAdminMe, getUser } from "services/user";
import "./App.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { setUser } from "app/features/appSlice";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { MessagePayload } from "firebase/messaging";
import { useFirebase } from "hooks/useFirebase";
import { useNotification } from "hooks/useNotification";
import useUser from "hooks/useUser";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getFirebaseToken, readSessionToken } from "services/auth";
import Error404 from "./Error404";
import Loading from "./Loading";
import RequireAuth from "./RequireAuth";

const SuperAdminDashboard = lazy(() => import("pages/SuperAdminDashboard"));
const OrganizationDashboard = lazy(() => import("pages/OrganizationDashboard"));
const EventDashboard = lazy(() => import("pages/EventDashboard"));
const SignIn = lazy(() => import("pages/auth/SignIn"));
const Settings = lazy(() => import("pages/Settings"));

const queryClient = new QueryClient();

const App = () => {
  const user = useUser();
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const firebaseApp = useFirebase();

  const onNotification = useCallback((payload: MessagePayload) => {
    const data = payload.data;
    const notification = payload.notification;
    if (data && notification) {
      if (data["module"] === "CHAT") {
        if ("Notification" in window) {
          new Notification(
            notification.title ?? "New Chat Message",
            notification,
          );
        }
      }
    }
  }, []);

  useNotification(onNotification);

  useEffect(() => {
    (async () => {
      const token = await readSessionToken();
      if (token) {
        try {
          const { typ } = jwtDecode<{ typ: number }>(token);
          setSessionToken(token);
          dispatch(setUser(await (typ === 1 ? getAdminMe() : getUser())));
        } catch (e) {
          console.error(e);
        } finally {
          setInitialized(true);
        }
      } else {
        setInitialized(true);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const token = await readSessionToken();
      if (token && user) {
        const { typ } = jwtDecode<{ typ: number }>(token);
        // do firebase login and set up the session for any realtime database need
        if (typ === 0) {
          await signInWithCustomToken(
            getAuth(firebaseApp),
            await getFirebaseToken(),
          );
        }
      }
    })();
  }, [firebaseApp, user]);

  return (
    <Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <Loading initialized={initialized}>
          <BrowserRouter>
            <Routes>
              <Route path="/auth/*" element={<SignIn />} />
              <Route
                path={"*"}
                element={
                  <RequireAuth signInPath={"/auth/sign-in"} user={user}>
                    <Routes>
                      <Route path="/settings/*" element={<Settings />} />
                      <Route
                        path="/e/:eventId/*"
                        element={<EventDashboard />}
                      />
                      <Route
                        path="/o/:organizationId/*"
                        element={<OrganizationDashboard />}
                      />
                      <Route path="/sa/*" element={<SuperAdminDashboard />} />
                      <Route
                        path="/"
                        element={
                          <>
                            {user && user.event && (
                              <Navigate to={`/e/${user.event.id}`} />
                            )}
                            {user && user.organization && (
                              <Navigate to={`/o/${user.organization.id}`} />
                            )}
                            {user && user.superAdmin && (
                              <Navigate to={`/sa/organizations`} />
                            )}
                          </>
                        }
                      />
                      <Route path="*" element={<Error404 />} />
                    </Routes>
                  </RequireAuth>
                }
              />
            </Routes>
          </BrowserRouter>
        </Loading>
      </QueryClientProvider>
    </Suspense>
  );
};
export default App;

import { MentionCandidate } from "components/editor/Editor";
import { User, UserInGroup } from "models/common";
import { toLocalEvent } from "services/event";
import { PagedResponse, get, patch, post } from "./api";

export const getUser = async () => {
  return get<any>("/users/me").then<User>((res: any) => {
    if (res.event) {
      res.event = toLocalEvent(res.event);
    }
    res.isSuperAdmin = res.superAdmin ?? false;
    res.isAdmin = false;
    return res;
  });
};

export const getMyEventNetwork = (eventId: string, excludes?: string[]) =>
  get<any>(`events/${eventId}/users/network`, {
    query: {
      excludes,
    },
  });

export const checkEmail = (
  email: string,
  eventKey?: string,
  excludeUserId?: string,
) =>
  post<boolean>("users/check-email", {
    data: {
      email,
      eventKey,
      userId: excludeUserId,
    },
  });

export const createUser = (eventId: string, data: FormData) =>
  post<any>(`events/${eventId}/users`, { data });

export const getMentionCandidates = (groupIds: string[], keyword: string) =>
  get<PagedResponse<UserInGroup>>(`users`, {
    query: {
      page: 0,
      size: 20,
      groupIds: groupIds.join(","),
      keyword,
    },
  }).then<MentionCandidate[]>((pagedResponse) => {
    return pagedResponse.data.map((res) => ({
      id: "@" + res.fullName,
      userId: res.id,
    }));
  });

export const updateMe = (data: FormData) =>
  patch<any>("users/me", { data }).then<User>((res) => {
    res.isAdmin = false;
    res.isSuperAdmin = res.superAdmin ?? false;
    return res;
  });

export const getAdminMe = () =>
  get<any>("admins/me").then<User>((res) => {
    res.isAdmin = true;
    res.isSuperAdmin = res.superAdmin ?? false;
    return res;
  });

export const updateAdminMe = (data: FormData) =>
  patch<any>("admins/me", { data }).then<User>((res) => {
    res.isAdmin = true;
    res.isSuperAdmin = res.superAdmin ?? false;
    return res;
  });

import { DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import generator from "generate-password-browser";
import { DisplayOrder } from "models/common";

export const isFunction = (obj: any): obj is Function =>
  typeof obj === "function";

export const isObject = (obj: any): obj is Object =>
  obj !== null && typeof obj === "object";

export const isPromise = (value: any): value is PromiseLike<any> =>
  isObject(value) && isFunction(value.then);

export const isNumeric = (n: any) => !isNaN(parseFloat(n)) && isFinite(n);

export const sortByField =
  <T extends object = {}>(field: keyof T, dir: "asc" | "desc" = "asc") =>
  (a: T, b: T) => {
    const k = dir === "asc" ? -1 : 1;
    if (a[field] < b[field]) return k * 1;
    if (a[field] > b[field]) return k * -1;
    return 0;
  };

export const randomString = (length: number = 4) => {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function generateUserPassword(): string {
  return generator.generate({
    length: 16,
    numbers: true,
    uppercase: true,
    symbols: true,
    lowercase: true,
    strict: true,
  });
}

export function debounce<T extends Function>(cb: T, wait = 300) {
  let h: NodeJS.Timeout;
  let callable = (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
  return callable as any as T;
}

export function downloadBlob(blob: Blob, fileName: string) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  setTimeout(() => {
    link.remove();
  }, 1000);
}

export function arrayEquals(a: any[], b: any[]): boolean {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

type HandledDragEndEvent<T> = {
  sorted: boolean;
  displayOrders: DisplayOrder[];
  sortedItems: T[];
};

export function handleDragEndEvent<
  T extends { id: UniqueIdentifier; displayOrder: number },
>(e: DragEndEvent, items: T[]): HandledDragEndEvent<T> {
  const { active, over } = e;

  if (active && over) {
    // 1. Rearrange the sorted items
    const sortedOriginalItems = [...items];
    const activeIndex = sortedOriginalItems.findIndex(
      (data) => data.id === active.id,
    );
    const newIndex = sortedOriginalItems.findIndex(
      (data) => data.id === over.id,
    );
    sortedOriginalItems.splice(
      newIndex,
      0,
      sortedOriginalItems.splice(activeIndex, 1)[0],
    );

    // 2. Calculate the DisplayOrders[]
    const displayOrders: DisplayOrder[] = [];
    const sortedItems: T[] = [];
    sortedOriginalItems.forEach((item, i: number) => {
      if (item.displayOrder !== i) {
        displayOrders.push({
          id: item.id.toString(),
          displayOrder: i,
        });
        // reassign, as "sorted"
      }
      sortedItems.push({ ...item, ...{ displayOrder: i } });
    });

    if (displayOrders.length > 0) {
      return {
        sorted: true,
        displayOrders,
        sortedItems,
      };
    }
  }

  return {
    sorted: false,
    displayOrders: [],
    sortedItems: [],
  };
}

// https://stackoverflow.com/a/61840474/534862
export async function createFileFromUrl(
  url: string,
  fileName?: string,
): Promise<File> {
  const name = fileName ?? url.split("/").pop() ?? "image.jpg";
  const response = await window.fetch(url, {
    mode: "cors",
  });
  const blob = await response.blob();
  return new File([blob], name, { type: blob.type });
}

import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { User } from "models/common";

interface RequireAuthProps {
  signInPath: string;
  children: ReactNode;
  user: User | null;
}

const RequireAuth = ({ children, signInPath, user }: RequireAuthProps) => {
  const location = useLocation();
  return user ? (
    <>{children}</>
  ) : (
    <Navigate to={signInPath} state={{ from: location }} replace />
  );
};

export default RequireAuth;

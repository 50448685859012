import { initializeApp } from "firebase/app";
import firebaseConfig from "firebaseConfig";
import { useMemo } from "react";

const databaseURL = process.env.REACT_APP_FIREBASE_REALTIME_DATABASE;
const fc = databaseURL
  ? {
      ...firebaseConfig,
      ...{ databaseURL },
    }
  : firebaseConfig;
const firebaseApp = initializeApp(fc);

export function getFirebaseApp() {
  return firebaseApp;
}

export function useFirebase() {
  return useMemo(() => {
    return firebaseApp;
  }, []);
}

import { signOut as firebaseSignOut } from "@firebase/auth";
import { getAuth } from "firebase/auth";
import { getFirebaseApp } from "hooks/useFirebase";
import { AuthTokenBag, Role, TwoFA, User } from "models/common";
import { deRegisterDevice } from "services/device";
import { del, get, post, setSessionToken } from "./api";

const MPLIFY_USER_TOKEN = "mplify-user-token";
const MPLIFY_REFRESH_TOKEN = "mplify-refresh-token";

export function isTwoFA(v: TwoFA | AuthTokenBag): v is TwoFA {
  return "verificationString" in v;
}
export function isAuthTokenBag(obj: TwoFA | AuthTokenBag): obj is AuthTokenBag {
  return "token" in obj && "refreshToken" in obj;
}

export const readSessionToken = async () => {
  return window.localStorage.getItem(MPLIFY_USER_TOKEN);
};

export const writeSessionToken = (token: string) => {
  window.localStorage.setItem(MPLIFY_USER_TOKEN, token);
};

export const destroySessionToken = async () => {
  window.localStorage.removeItem(MPLIFY_USER_TOKEN);
};

export const readRefreshToken = async () => {
  return window.localStorage.getItem(MPLIFY_REFRESH_TOKEN);
};

export const writeRefreshToken = (token: string) => {
  window.localStorage.setItem(MPLIFY_REFRESH_TOKEN, token);
};

export const destroyRefreshToken = async () => {
  window.localStorage.removeItem(MPLIFY_REFRESH_TOKEN);
};

export const signIn = (
  role: Role,
  key: string,
  emailAddress: string,
  password: string,
): Promise<TwoFA | AuthTokenBag> => {
  const data: Record<string, any> = {
    emailAddress,
    password,
  };
  if (role === "MANAGER" || role === "ADMIN") {
    data[role === "MANAGER" ? "eventKey" : "orgKey"] = key;
  }
  return post<{ token?: string; user?: User; verificationString?: string }>(
    "/auth/dashboard-login",
    {
      data,
    },
  ).then((res) => {
    if (res.token) {
      return res as AuthTokenBag;
    } else {
      return { verificationString: res.verificationString } as TwoFA;
    }
  });
};

export const twoFA = (verificationString: string, token: string) =>
  post<AuthTokenBag>("auth/verify-two-factor", {
    data: {
      verificationString,
      code: token,
    },
  }).then((res) => {
    return res;
  });

export const forgotPassword = (email: string, role: Role, key: string) => {
  const data: Record<string, string> = {
    emailAddress: email,
  };
  if (role === "MANAGER" || role === "ADMIN") {
    data[role === "MANAGER" ? "eventKey" : "orgKey"] = key;
  }
  return post<any>("auth/forget-password", { data });
};

export const resetPassword = (token: string, newPassword: string) =>
  post<any>("auth/reset-password", {
    data: {
      newPassword,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const signOut = async (user: User) => {
  if (!user.isAdmin) {
    await deRegisterDevice();
  }
  await destroySessionToken();
  await destroyRefreshToken();
  const auth = getAuth(getFirebaseApp());
  await firebaseSignOut(auth);
};

export const refreshToken = () =>
  new Promise<AuthTokenBag>((resolve, reject) => {
    (async () => {
      const refreshToken = await readRefreshToken();
      if (refreshToken) {
        post<AuthTokenBag>("auth/refresh-token", {
          data: {
            refreshToken,
          },
        })
          .then((res) => {
            setSessionToken(res.token);
            writeSessionToken(res.token);
            writeRefreshToken(res.refreshToken);
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      } else {
        reject("no refresh token");
      }
    })();
  });

export const getAuthenticatorQRContent = () =>
  get<{
    barcodeUrl: string;
    secretKey: string;
  }>("auth/enable-authenticator");

export const enableAuthenticator = (token: string) =>
  post<any>("auth/enable-authenticator", {
    data: { code: token },
  });

export const disableAuthenticator = (token: string) =>
  del<any>("auth/disable-authenticator", {
    data: { code: token },
  });

export const getFirebaseToken = (): Promise<string> =>
  get<any>("auth/firebase-token").then((res) => res.token);

import { setNotificationStatus } from "app/features/appSlice";
import { RootState } from "app/store";
import {
  MessagePayload,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import { useFirebase } from "hooks/useFirebase";
import useUser from "hooks/useUser";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerDevice } from "services/device";

export function useNotification(
  onNotification?: (payload: MessagePayload) => void,
): [NotificationPermission, (ns: NotificationPermission) => void] {
  const { notificationStatus } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const user = useUser();

  const dispatcher = (status: NotificationPermission) => {
    dispatch(setNotificationStatus(status));
  };

  const firebaseApp = useFirebase();

  useEffect(() => {
    let unsubscribe = () => {};
    (async () => {
      if (notificationStatus === "granted" && user && !user.isAdmin) {
        try {
          const messaging = getMessaging(firebaseApp);
          await registerDevice(await getToken(messaging));
          if (onNotification) {
            unsubscribe = onMessage(messaging, onNotification);
          }
        } catch (e) {
          console.log(e);
          // left empty
        }
      }
    })();
    return () => {
      unsubscribe();
    };
  }, [firebaseApp, notificationStatus, onNotification, user]);

  return [notificationStatus, dispatcher];
}

import { languages } from "data/languages";
import { sortByField } from "utils/common";
import {
  BackupTask,
  CustomField,
  DisplayOrder,
  Event,
  Language,
  Page,
} from "models/common";
import { del, get, paged, patch, post } from "services/api";

export const getAccessibleEvents = () => get<Event[]>(`events`);

export const getOrganizationEvents = (organizationId: string) =>
  paged<Event>(`organizations/${organizationId}/events`).all();

export const toLocalEvent = (event: any): Event => ({
  ...event,
  languages: event.languages
    .map((e: { name: string; default: boolean }) => ({
      id: e.name,
      label: languages[e.name] ?? e.name,
      default: e.default,
    }))
    .sort(sortByField("label")),
});

export const getEvent = (eventId: string) =>
  get<any>(`events/${eventId}`).then<Event>((res) => toLocalEvent(res));

export const createEvent = (organizationId: string, data: FormData) =>
  post<any>(`organizations/${organizationId}/events`, { data }).then<Event>(
    (res) => toLocalEvent(res),
  );

export const updateEvent = (eventId: string, data: FormData) =>
  patch<any>(`events/${eventId}`, { data }).then<Event>((res) =>
    toLocalEvent(res),
  );

export const deleteEvent = (eventId: string) =>
  del<any>(`events/${eventId}`).then<Event>((res) => toLocalEvent(res));
export const restoreEvent = (eventId: string) =>
  post<any>(`events/${eventId}/restore`).then<Event>((res) =>
    toLocalEvent(res),
  );

export const getEventLanguages = (eventId: string): Promise<Language[]> =>
  get<{ name: string; default: boolean }[]>(`events/${eventId}/languages`).then(
    (eventLanguages) =>
      eventLanguages.map((eventLanguage) => ({
        id: eventLanguage.name,
        label: languages[eventLanguage.name] ?? eventLanguage.name,
      })),
  );

export const createEventLanguage = (eventId: string, lang: string) =>
  post<{ name: string; default: boolean }>(`events/${eventId}/languages`, {
    data: { lang },
  });

export const deleteEventLanguage = (eventId: string, lang: string) =>
  del<{ name: string; default: boolean }>(
    `events/${eventId}/languages/${lang}`,
  );

export const updateEventDefaultLanguage = (eventId: string, lang: string) =>
  patch<{ name: string; default: boolean }>(`events/${eventId}/languages`, {
    data: { lang },
  });

export const setEventLanguages = (
  eventId: string,
  defaultLang: string,
  languages: string[],
) => {
  const data = new FormData();
  data.append("lang", defaultLang);
  languages.forEach((lang, i) => {
    data.append(`translations[${i}].lang`, lang);
  });
  return patch<any>(`events/${eventId}`, { data });
};

export const getPages = (eventId: string) =>
  get<Page[]>(`events/${eventId}/pages`);

export const getPage = (eventId: string, module: string) =>
  get<Page>(`events/${eventId}/pages/${module}`);

export const createPage = (eventId: string, module: string, data: FormData) =>
  post<Page>(`events/${eventId}/pages/${module}`, { data });

export const updatePage = (eventId: string, module: string, data: FormData) =>
  patch<Page>(`events/${eventId}/pages/${module}`, { data });

export const deletePage = (eventId: string, module: string) =>
  del<any>(`events/${eventId}/pages/${module}`);

export const updatePageDisplayOrders = (
  eventId: string,
  displayOrders: DisplayOrder[],
) =>
  patch<void>(`events/${eventId}/pages`, {
    data: displayOrders,
  });

export const createPageBanners = (
  eventId: string,
  module: string,
  banners: {
    displayOrder: number;
    banner: File;
  }[],
) => {
  const data = new FormData();
  banners.forEach((banner, i: number) => {
    data.append(`pageBannerCreateRequests[${i}].banner`, banner.banner);
    data.append(
      `pageBannerCreateRequests[${i}].displayOrder`,
      banner.displayOrder.toString(10),
    );
  });
  return post<any>(`events/${eventId}/pages/${module}/page-banners`, { data });
};

export const updatePageBanners = (
  eventId: string,
  module: string,
  displayOrders: DisplayOrder[],
) =>
  patch<any>(`events/${eventId}/pages/${module}/page-banners`, {
    data: displayOrders,
  });

export const deletePageBanner = (eventId: string, bannerId: string) =>
  del(`events/${eventId}/page-banners/${bannerId}`);

export const checkCustomFieldKey = (eventId: string, key: string) =>
  post<boolean>(`events/${eventId}/event-custom-fields/check-key`, {
    data: { key },
  });

export const getCustomFields = (eventId: string) =>
  paged<CustomField>(`events/${eventId}/event-custom-fields`)
    .sort([
      {
        id: "displayOrder",
        desc: false,
      },
    ])
    .all();

export const createCustomField = (
  eventId: string,
  key: string,
  label: string,
  displayOrder: number,
) =>
  post<CustomField>(`events/${eventId}/event-custom-fields`, {
    data: {
      key,
      label,
      displayOrder,
    },
  });

export const updateCustomField = (
  eventId: string,
  id: string,
  key: string,
  label: string,
  displayOrder: number,
) =>
  patch<CustomField>(`events/${eventId}/event-custom-fields/${id}`, {
    data: {
      key,
      label,
      displayOrder,
    },
  });

export const deleteCustomField = (eventId: string, id: string) =>
  del<void>(`events/${eventId}/event-custom-fields/${id}`);

export const updateCustomFieldDisplayOrders = (
  eventId: string,
  displayOrders: DisplayOrder[],
) =>
  patch<void>(`events/${eventId}/event-custom-fields`, {
    data: displayOrders,
  });

export const updateEventOnline = (eventId: string, online: boolean) =>
  patch<Event>(`events/${eventId}/status`, {
    data: { online },
  }).then<Event>((res) => toLocalEvent(res));

export const updateEventEnabled = (eventId: string, enabled: boolean) =>
  patch<Event>(`events/${eventId}/status`, {
    data: { enabled },
  }).then<Event>((res) => toLocalEvent(res));

export const createBackupTask = (eventId: string, contactEmail: string) =>
  post<BackupTask>(`events/${eventId}/backup-tasks`, {
    data: { contactEmail },
  });

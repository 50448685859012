import { ReactNode } from "react";
import "./Loading.scss";

interface LoadingProps {
  initialized?: boolean;
  skeleton?: ReactNode;
  children?: ReactNode;
}
const Loading = ({ initialized = false, skeleton, children }: LoadingProps) =>
  initialized ? (
    <>{children}</>
  ) : skeleton ? (
    <>{skeleton}</>
  ) : (
    <div className={"Loading"}>Loading...</div>
  );
export default Loading;

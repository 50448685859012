import { configureStore } from "@reduxjs/toolkit";
import appReducer, { AppState } from "./features/appSlice";

export type RootState = {
  app: AppState;
};

const store = configureStore({
  reducer: {
    app: appReducer,
  },
});

//store.subscribe(() => console.log("REDUX", store.getState()));
export default store;
